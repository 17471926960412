import { makeAutoObservable, runInAction } from 'mobx';
import { authApi } from '@helpers/AxiosHelper';
import { isMobile } from '@helpers/EnvHelper';
import { getSessionStorage, setSessionStorage } from '@helpers/StorageHelper';
import { LOGIN_MEMBER_INFO } from '@const/env';

export const initial = {
  memberInfo: {
    memberSeq: 0,
    authorGroupSeq: 0, // (1:최고관리자, 2:관리자, 3:고객사/제조사/공급처, 4:센터장, 5:엔지니어)
    enterpriseSeq: 0,
    id: '',
    nm: '',
    isIssuedPassword: -1, //0:미발급,1:발급
  },
  menuList: [],
  menu: {
    menuSeq: 1,
    upperMenuSeq: 0,
    menuLevel: 1,
    sortOrdr: 0,
    menuNm: '',
    url: null,
    isDisplayMobile: null, // 모바일 노출 여부(0:미사용, 1:사용)
    isGenerativeBbs: null, // 생성형게시판 여부(0:false, 1:true)
    isBookmarked: null,
    menuBookmarkSeq: null,
    authorGroupSeq: null,
    inqireAuthor: null,
    updtAuthor: null,
    iconNm: null,
    childMenus: [],
  },

  bookmarkList: [
    {
      menuSeq: 0,
      upperMenuSeq: 0,
      menuLevel: 1,
      sortOrdr: 0,
      menuNm: '즐겨찾기메뉴',
      url: null,
      childMenus: [],
      isDisplayMobile: 0, // 모바일 노출 여부(0:미사용, 1:사용)
      isGenerativeBbs: 0, // 생성형게시판 여부(0:false, 1:true)
      isBookmarked: 0,
      menuBookmarkSeq: 0,
      iconNm: '',
      authorGroupSeq: null,
      inqireAuthor: null,
      updtAuthor: null,
    },
  ],
};

export default function createGlobalStore(rootStore) {
  return makeAutoObservable({
    isLoading: false,
    menuList: initial.menuList,
    bookmarkList: initial.bookmarkList,
    memberInfo: null,

    alertMessage: '',

    setLoading(isLoading) {
      runInAction(() => {
        this.isLoading = isLoading;
      });
    },

    async getMenu() {
      if (isMobile()) {
        return await this.getMobileMenu();
      } else {
        return await this.getPcMenu();
      }
    },

    async getPcMenu() {
      try {
        const { data } = await authApi.get(
          '/api/menu',
          {
            deviceType: 0,
          },
          null,
        );
        runInAction(() => {
          this.menuList = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        alert(alertMessage);
        return false;
      }
    },

    async getMobileMenu() {
      try {
        const { data } = await authApi.get(
          '/api/menu/mobile',
          {
            deviceType: 1,
          },
          null,
        );
        runInAction(() => {
          this.menuList = data;

          // 모바일 일경우 설정 메뉴
          this.menuList.push({
            menuSeq: 0,
            upperMenuSeq: 0,
            menuLevel: 1,
            sortOrdr: 99,
            menuNm: '설정',
            url: '/setting',
            isDisplayMobile: 1, // 모바일 노출 여부(0:미사용, 1:사용)
            isGenerativeBbs: 0, // 생성형게시판 여부(0:false, 1:true)
            isBookmarked: 0,
            menuBookmarkSeq: null,
            authorGroupSeq: 5,
            inqireAuthor: 1,
            updtAuthor: 1,
            iconNm: '',
            childMenus: [],
          });
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        return false;
      }
    },

    async getBookmarkMenu() {
      try {
        const { data } = await authApi.get(
          `/api/menu/menu-bookmark`,
          null,
          null,
        );

        runInAction(() => {
          this.bookmarkList[0].childMenus = data;
        });
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        return false;
      }
    },

    async getBookmark(menuSeq) {
      try {
        const { data } = await authApi.get(
          `/api/menu/menu-bookmark/${menuSeq}`,
          null,
          null,
        );

        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        return null;
      }
    },

    async addBookmark(menuSeq) {
      try {
        const { data } = await authApi.post('/api/menu/menu-bookmark', {
          menuSeq,
        });
        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        return null;
      }
    },

    async deleteBookmark(menuBookmarkSeq) {
      try {
        await authApi.delete(
          `/api/menu/menu-bookmark/${menuBookmarkSeq}`,
          null,
        );
        return true;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        return false;
      }
    },

    // 에디터 이미지 업로드 용
    async toastUiEditorFileUpload(file) {
      try {
        const formData = new FormData();
        formData.append('multipartFile', file);

        const {
          data: { changeFileNm, fileLink, orginlFileNm },
        } = await authApi.fileUploadPost(
          '/api/community/common/editor',
          formData,
        );

        return {
          changeFileNm,
          fileLink,
          orginlFileNm,
        };
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        return {};
      }
    },

    // 로그인한 회원 정보 조회
    async getMember() {
      try {
        const { data } = await authApi.get('/api/login/member', null, null);

        runInAction(() => {
          this.memberInfo = data;
          setSessionStorage(LOGIN_MEMBER_INFO, data);
        });
        return data;
      } catch ({
        data: {
          data: { alertMessage },
        },
      }) {
        this.memberInfo = null;
        this.alertMessage = alertMessage;
        return false;
      }
    },

    getAlertMessage() {
      const alertMessage = this.alertMessage;
      runInAction(() => {
        this.alertMessage = '';
      });
      return alertMessage;
    },
  });
}
